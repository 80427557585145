import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { NextUIProvider } from '@nextui-org/react'
import cn from 'classnames'
import ReactGA from 'react-ga4'
import _ from 'lodash'

import { GetComments } from './components/GetComments'

import { GlobalStateProvider } from './context/state'

import './styles.scss'

import { keywords } from './keywords.json'

const TRACKING_ID = 'G-JBTGS1J74H'

ReactGA.initialize(TRACKING_ID)

export const App = ({ isBot, loadingFromServer }) => {
  const actualIsBot = isBot || (!process.env.SERVER && window.isBot)

  return (
    <NextUIProvider>
      <GlobalStateProvider>
        <div className={cn('my-container', 'white-bg')}>
          {actualIsBot ? <a href='/sitemap'>Sitemap</a> : null}
          <Routes>
            <Route path="/" element={<GetComments isBot={actualIsBot} loadingFromServer={loadingFromServer} />}>
              <Route path=":videoId" element={<GetComments isBot={actualIsBot} loadingFromServer={loadingFromServer} />} />
            </Route>
            {keywords.map(({ keyword, description }) => {
              return (
                <Route
                  path={keyword.replaceAll(' ', '-')}
                  key={_.capitalize(keyword)}
                  element={<GetComments isBot={actualIsBot} title={keyword} description={description} />}
                />
              )
            })}
            <Route path='/sitemap' element={!actualIsBot
              ? <Navigate to="/" replace />
              : (
                  <ul>
                    {keywords.map(({ keyword }) => <li key={keyword}><a href={keyword.replaceAll(' ', '-')}>{keyword}</a></li>)}
                  </ul>
                )
            }/>
            <Route path="/finder" element={<GetComments isBot={actualIsBot} title="youtube comments finder" description='Youtube comments finder. Also you can sort comments. And search for keyword. *Best UI*' />} />
            <Route path="/search" element={<GetComments isBot={actualIsBot} />} />
            <Route path="/sort-youtube-comments" element={<GetComments isBot={actualIsBot} title="Sort youtube comments" description='Sort youtube comments. And search for keyword. *Best UI*' />} />
            <Route path="/sort-youtube-comments-mobile" element={<GetComments isBot={actualIsBot} title="Sort youtube comments" description='Sort youtube comments mobile. And search for keyword. *Best UI*' />} />
            <Route path="/youtube-mobile-sort-comments" element={<GetComments isBot={actualIsBot} title="Sort youtube comments" description='Youtube mobile sort comments. And search for keyword. *Best UI*' />} />
            <Route path="/first-youtube-comment-finder" element={<GetComments isBot={actualIsBot} title='First youtube Comment Finder' description='First youtube comment finder. Also you can sort comments. And search for keyword. *Best UI*' />} />
            <Route path="/get-comments" element={<GetComments isBot={actualIsBot} />}>
              <Route path=":videoId" element={<GetComments isBot={actualIsBot} />} />
            </Route>
          </Routes>
        </div>
      </GlobalStateProvider>
    </NextUIProvider>
  )
}
